<template>
  <div class="wrapper">
    <base-button
      icon
      type="primary"
      class="flotingBtn"
      :class="[isActive ? 'tagBtn2' : 'tagBtn']"
      @click="isActive = !isActive"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-right"></i></span>
    </base-button>
    <base-button
      icon
      type="primary"
      class="flotingBtn2"
      @click="toggleClass"
      :class="[isActive ? 'tagBtn' : 'tagBtn2']"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-left"></i></span>
    </base-button>
    <Sidebar :class="{ tagBtn: isActive }" />
    <div class="main-content" :class="{ DashboardBody: isActive }">
      <SampleNavbar />
      <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
        <b-breadcrumb>
          <b-breadcrumb-item href="#">Employee</b-breadcrumb-item>
          <b-breadcrumb-item href="#" active>Employee Type</b-breadcrumb-item>
        </b-breadcrumb>
      </base-header>
      <b-container fluid class="mt--7">
        <b-row>
          <b-col md="4">
            <b-card type="default" header-classes="bg-transparent">
              <b-form style="text-align: left">
                <b-row>
                  <b-col md="12">
                    <label>Employee Type*</label>
                    <b-input
                      type="text"
                      placeholder="Add Employee Type"
                    ></b-input>
                  </b-col>
                  <b-col md="12" class="mt-4">
                    <base-button block type="primary"
                      >Add Employee Type</base-button
                    >
                  </b-col>
                </b-row>
              </b-form>
            </b-card>
          </b-col>
          <b-col md="8">
            <b-card type="default" header-classes="bg-transparent">
              <b-row class="mb-2">
                <b-col md="8"></b-col>
                <b-col md="4"
                  ><base-button block type="primary" @click="csvExport(csvData)"
                    >Export to CSV</base-button
                  ></b-col
                >
              </b-row>
              <vue-good-table
                :columns="columns"
                :rows="rows"
                :line-numbers="true"
                :search-options="{
                  enabled: true,
                  placeholder: 'Search this table',
                }"
                :pagination-options="{
                  enabled: true,
                  mode: 'records',
                }"
              >
                <template `slot`="table-row" `slot-scope`="props" style="w">
                  <span v-if="props.column.field == 'after'">
                    <base-button icon type="primary" size="sm">
                      <span class="btn-inner--icon"
                        ><i class="far fa-edit"></i
                      ></span>
                    </base-button>
                    <base-button
                      icon
                      type="danger"
                      size="sm"
                      @click="checkedClick(props.row.employee_type_id)"
                    >
                      <span class="btn-inner--icon">
                        <i
                          class="ni ni-check-bold"
                          v-if="props.row.employee_type_active"
                        ></i>
                        <i class="ni ni-fat-remove" v-else></i>
                      </span>
                    </base-button>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
      <Footer />
    </div>
  </div>
</template>
<script>
import Sidebar from "./../../Starter/Sidebar";
import SampleNavbar from "./../../Starter/SampleNavbar";
import Footer from "./../../Starter/SampleFooter";
import { API_URL } from "../../../router/api";
export default {
  name: "employee-type",
  components: {
    Sidebar,
    SampleNavbar,
    Footer,
  },
  data() {
    return {
      isActive: false,
      checked: true,
      columns: [
        {
          label: "Employee Type",
          field: "employee_type_role",
        },
        {
          label: "Actions",
          field: "after",
        },
      ],
    };
  },
  computed: {
    csvData() {
      return this.rows.map((item) => ({
        ...item,
        //  address: 'адрес', // item.address.city,
        //  company: 'компания'// item.company.name
      }));
    },
    rows() {
      return this.$store.state.employeeType;
    },
  },
  methods: {
    toggleClass: function (event) {
      this.isActive = !this.isActive;
    },
    checkedClick(id) {
      this.rows.map((row) => {
        if (row.employee_type_id === id) {
          row.employee_type_active = !row.employee_type_active;
          let active = row.employee_type_active;
          this.axios
            .post(API_URL + "admin_employee_type_active", {
              id: id,
              active: active,
            })
            .then((res) => {
              let status = res.data.status;
              let message = res.data.message;
              if (status == true) {
                this.$store.dispatch("getEmployeeTypeList");
                this.$swal.fire({
                  icon: "success",
                  text: `${message}`,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                });
              } else {
                this.$swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: `${message}`,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                });
              }
            });
        }
        return row;
      });
    },
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(";"),
        ...arrData.map((item) => Object.values(item).join(";")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "employee-type.csv");
      link.click();
    },
  },
};
</script>
<style lang="scss">
.DashboardBody {
  margin-left: 0px !important;
}
.tagBtn {
  display: none !important;
}
.tagBtn2 {
  display: block !important;
}
</style>
